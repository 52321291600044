import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class ApplicationRoute extends Route {
  @service modalWindow;
  @service currentUser;
  @service globalVars;
  @service navigation;
  @service session;
  @service router;
  @service store;
  @service intl;
  @service wss;
  @service moment;

  async beforeModel(transition) {
    await this.session.setup();

    this.moment.setTimeZone('UTC');
    this.session.requireAuthentication(transition, 'login');

    return window.loadPartnerConfigPromise.then(async () => {
      this.globalVars.run();

      // eslint-disable-next-line ember/no-observers
      this.router.addObserver('currentRouteName', () => {
        this.intl.missingLanguages();
      })
      // eslint-disable-next-line ember/no-observers
      this.router.addObserver('currentRouteName', () => {
        let route = this.router.currentRouteName.split('.')[0];
        if (route.split('-')) {
          route = route.replace('-', '_');
        }
        let content = this.store.peekAll('site-content').find(i => i.definer === route);
        if (content && !this.navigation.isFavorite) {
          this.navigation.selected = content.id
        }
      })

      // await this.wss.connect();

      const translations = await this.intl.loadCurrentLanguage();
      this.intl.addTranslations('en-us', await translations.json());
    })
  }

  async _loadCurrentUser() {
    await this.currentUser.load();
  }

  async model() {
    return this._loadCurrentUser();
  }

  setupController(controller) {
    controller.set('currentUser', this.currentUser);
    controller.set('modalWindow', this.modalWindow);
  }
}
